import React, { useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";
import "./App.css";

const FRAME_RATE = 1; // Desired frame rate

const WavyBackground = ({ children, ...props }) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const noise = createNoise3D();
    let w, h, nt, ctx, canvas;
    let lastFrameTime = 0;
    let isAnimating = true;

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio < 0.8) {
        isAnimating = false;
        // console.log('WavyBackground animation paused');
      } else {
        isAnimating = true;
        // console.log('WavyBackground animation running');
        animationRef.current = requestAnimationFrame(drawWave);
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, { threshold: 0.8 });

    const getSpeed = () => {
      const speed = getComputedStyle(document.documentElement).getPropertyValue('--wavy-speed').trim();
      return speed === "fast" ? 0.002 : 0.001;
    };

    const init = () => {
      canvas = canvasRef.current;
      ctx = canvas.getContext("2d");
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
      ctx.filter = `blur(${getComputedStyle(document.documentElement).getPropertyValue('--wavy-blur').trim()})`;
      nt = 0;
      observer.observe(canvas);
    };

    const waveColors = getComputedStyle(document.documentElement).getPropertyValue('--wavy-colors').split(',');
    const waveWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--wavy-wave-width'));
    const backgroundFill = getComputedStyle(document.documentElement).getPropertyValue('--wavy-background-fill').trim();
    const opacity = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--wavy-opacity'));

    const drawWave = (currentTime) => {
      if (!isAnimating) return;
      // Convert the desired FPS to milliseconds
      const frameInterval = 1000 / FRAME_RATE;

      // Check if enough time has passed since the last frame
      if (currentTime - lastFrameTime < frameInterval) {
        animationRef.current = requestAnimationFrame(drawWave);
        return;
      }

      // Update the last frame time
      lastFrameTime = currentTime;

      ctx.fillStyle = backgroundFill;
      ctx.globalAlpha = opacity;
      ctx.fillRect(0, 0, w, h);

      nt += getSpeed();
      
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.lineWidth = waveWidth;
        ctx.strokeStyle = waveColors[i % waveColors.length].trim();
        
        for (let x = 0; x < w; x += 20) {
          const y = noise(x / 800, 0.3 * i, nt) * 100 + h * 0.5;
          ctx.lineTo(x, y);
        }
        
        ctx.stroke();
      }

      animationRef.current = requestAnimationFrame(drawWave);
    };

    const handleResize = () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
      ctx.filter = `blur(${getComputedStyle(document.documentElement).getPropertyValue('--wavy-blur').trim()})`;
    };

    const startAnimation = () => {
      animationRef.current = requestAnimationFrame(drawWave);
    };

    init();
    startAnimation();

    window.addEventListener('resize', handleResize);

    return () => {
      cancelAnimationFrame(animationRef.current);
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="wavy-background" {...props}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      <div className="content">{children}</div>
    </div>
  );
};

export default WavyBackground;