import React from 'react';
import './PulsatingCircle.css';

const PulsatingCircle = () => {
  return (
    <div className="pulsating-circle"></div>
  );
};

export default PulsatingCircle;
