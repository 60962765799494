import React from 'react';
import './InsightVisual.css';
import PulsatingCircle from './PulsatingCircle';
import transcriptImage from './transcripts.svg'; // Replace with the path to your image
import arrowImage from './arrow.svg'; // Replace with the path to your arrow image
import barChartImage from './bar_chart.svg';

function InsightVisual() {
  return (
      <div className="visual-container">
          <img src={transcriptImage} alt="Transcripts" className="transcript-image" />

        <img src={arrowImage} alt="Arrow" className="arrow-image" />
        <div className="pulsating-circle-container">
          <PulsatingCircle />
        </div>
        <img src={arrowImage} alt="Arrow" className="arrow-image" />
        <img src={barChartImage} alt="BarChart" className="bar-chart-image" />
      </div>
  );
}

export default InsightVisual;
