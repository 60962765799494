import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import './App.css';
import logo from './echovane_logo.svg';
import easy_visual from './section_3.gif';
//import AuroraBackground from './AuroraBackground'; 
import WavyBackground from './WavyBackground'; 
import WorldMap from './WorldMap';  // Import WorldMap component
import customerFeedbackIcon from './customer_feedback.svg';
import consumerResearchIcon from './consumer_research.svg';
import conceptTestingIcon from './concept_testing.svg';
import InsightVisual from './InsightVisual'; // Adjust the path as necessary

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AnimatedElement = ({ className, children }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 0.8) {
          element.classList.add('paused');
          element.classList.remove('running');
          // console.log(`${element.className} animation paused`);
        } else {
          element.classList.add('running');
          element.classList.remove('paused');
          // console.log(`${element.className} animation running`);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.8 });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div className={className} ref={elementRef}>
      {children}
    </div>
  );
};


function App() {
  const bookDemoLink = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3aYAVEHLI20PRmdDice4QYpJ05yplDA88Y2nzRO1xkpnqsjTvlSyWaOO_0ojBBsx6kAcND8D66';

  const handleBookDemoClick = () => {
    mixpanel.track('BookDemoClicked');
    window.open(bookDemoLink, '_blank');
  };
  

  const handleButtonClick = (url, useCase = '') => {
    if (url === 'https://dashboard.echovane.com/login?screen_hint=signup') {
      mixpanel.track('SignupClicked');
    } else if (url === 'https://dashboard.echovane.com/') {
      mixpanel.track('LoginClicked', { button: 'Login' });
    } else if (/^https:\/\/feedback\.echovane\.com\/interviews/.test(url)) {
      mixpanel.track('LiveDemoClicked', { useCase });
    }
  
    window.open(url, '_blank');
  };
  
  

  const hasTrackedHomePageViewed = useRef(false);

  // const [open, setOpen] = useState(false);
  // const [userInfo, setUserInfo] = useState(null);
  // const [demoDialogOpen, setDemoDialogOpen] = useState(false);
 

  // const handleDemoButtonClick = () => {
  //   setDemoDialogOpen(true);
  // };

  // const handleDemoSubmit = (email) => {
   
  //   setDemoDialogOpen(false);
  //   // Show confirmation message here if needed
  //   alert('You will receive a demo link with instructions soon, check your inbox.');
  // };

  useEffect(() => {
    // const storedUserInfo = localStorage.getItem('userInfo');
    // if (storedUserInfo) {
    //   setUserInfo(JSON.parse(storedUserInfo));
    // }
      if (!hasTrackedHomePageViewed.current) {
    mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: true, track_pageview: false, persistence: 'localStorage' });
    mixpanel.track('HomePageViewed');
    hasTrackedHomePageViewed.current = true;
  }

    return () => {

    };
  }, []);


  // const handleUserInfoSubmit = (userInfo) => {
  //   localStorage.setItem('userInfo', JSON.stringify(userInfo));
  //   setUserInfo(userInfo);
  //   setOpen(false);
  // };

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Echovane | Collect In-Depth Product Feedback Effortlessly</title>
          <meta name="description" content="Echovane is the best solution for product managers, researchers, social media managers, community managers, and business leaders to collect in-depth product feedback and user feedback effortlessly." />
        </Helmet>
        <AnimatedElement className="wavy-background">
          <WavyBackground>
            
        <header className="hero-section">
          <div className='nav-bar'>
            <img src={logo} alt="Echovane" className='logo' />
            <div className="button-container">
            <Button 
              variant="contained" 
              className='signup-button' 
              onClick={() => handleButtonClick('https://dashboard.echovane.com/login?screen_hint=signup')}>
              Try for Free
            </Button>

            <Button 
              variant="contained" 
              className='signup-button-login' 
              onClick={() => handleButtonClick('https://dashboard.echovane.com/')}>
              Login
            </Button>
              </div>

          </div>
          <div className='hero-content'>
            <div className='hero-text-content'>
              <h1 className='hero-text-title'>
                Conduct market research and collect in-depth feedback effortlessly
              </h1>
              <p> Using our Voice Conversational AI survey tool, understand consumer behaviours, test concepts and gather rich, open-ended feedback at scale—easily in just a few hours.</p>
              <div className="button-container-hero">
                  <Button 
                    variant="contained" 
                    className='signup-button' 
                    onClick={() => handleButtonClick('https://dashboard.echovane.com/login?screen_hint=signup')}>
                    Get started for Free
                  </Button>

                  <Button variant="contained" className='book-demo-button' onClick={handleBookDemoClick}>
                    BOOK DEMO
                  </Button>
              </div>
              <div className="video-container">
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe 
                    src="https://player.vimeo.com/video/1029487312?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0&title=0" 
                    frameBorder="0" 
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden", margin:"0", padding:"0" }} 
                    title="Echovane Consumer Research Demo"
                  ></iframe>
                </div>


              </div>
              {/* <Button variant="contained" className='book-demo-button-2'  onClick={() => handleButtonClick('https://dashboard.echovane.com/login?screen_hint=signup')}>
                Try for free
              </Button> */}
            </div>
          </div>
            </header>
            <div className="colored-area"></div>
          </WavyBackground>
        </AnimatedElement>
         
        <section className="use-case-section">
          <h2 className="use-case-title">Use Cases</h2>
          <div className="use-case-boxes">

          <AnimatedElement>
            <div className="use-case-box">
              <h3 className="use-case-box-title">
                <img src={consumerResearchIcon} alt="Consumer Research" className="use-case-icon" />
                Consumer research
              </h3>
              <p className="use-case-box-description">Identify the current behaviours of your target users and uncover new, unmet needs and opportunities.</p>
              <button className="use-case-button" onClick={() => handleButtonClick('https://feedback.echovane.com/interviews/3f2f3e1d-527a-43e4-b2bf-c176f2a6854a', 'Consumer Research')}>
              TRY LIVE DEMO
                
                  <div className="live-animation"></div>
                
              </button>
            </div>
            </AnimatedElement>

            <AnimatedElement>
            <div className="use-case-box">
              <h3 className="use-case-box-title">
                <img src={customerFeedbackIcon} alt="Customer Feedback" className="use-case-icon" />
                Customer feedback
              </h3>
              <p className="use-case-box-description">Collect detailed, open-ended feedback on your product at scale, surpassing the limitations of traditional surveys.</p>
                <button className="use-case-button" onClick={() => handleButtonClick("https://feedback.echovane.com/interviews/2a6c70d2-77cc-4977-a704-f6a709804d3b", 'Customer Feedback')}>
                  TRY LIVE DEMO
                  <div className="live-animation"></div>
                </button>

            </div>
            </AnimatedElement>


            <AnimatedElement>
            <div className="use-case-box">
              <h3 className="use-case-box-title">
                <img src={conceptTestingIcon} alt="Concept Testing" className="use-case-icon" />
                Concept testing
              </h3>
              <p className="use-case-box-description">Test your ideas, hypotheses, product concepts, and ads before you start building to see what works best.</p>
              <button className="use-case-button" disabled>
                Demo coming soon..
                
                  {/* <div className="live-animation"></div> */}
                
              </button>
            </div>
            </AnimatedElement>

          </div>
        </section>
        <section className="features-section">
          <div className="features-left">
            <h2 className="features-title">Designed to research</h2>
            {/* <p className="ai_name">ECHO AI</p> */}

            {<p className="features-description">Meet <span className="first-word">EchoAI</span>: skilled at building rapport, asking insightful questions, actively listening, interpreting non-verbal cues, and turning diverse perspectives into actionable insights.</p> }
            <AnimatedElement className="animated-element">
              <div className="pulsating-circle"></div>
            </AnimatedElement>

          </div>
          <div className="features-right">
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                   <span className="feature-number">01</span>
                  Intelligent Probing
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Excels in probing respondents intelligently, ensuring that you get deep, insightful answers without the need for repetitive questioning.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                  <span className="feature-number">02</span>
                  Understands Hesitation
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Equipped with the ability to detect hesitation from respondents and adapt seamlessly, allowing the conversation to flow naturally and comfortably.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                  <span className="feature-number">03</span>
                  Natural Conversation
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Engages in a conversation that feels incredibly natural, with no need for clicks or manual input. Just talk, and it will understand and respond accordingly.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                  <span className="feature-number">04</span>
                  Unbiased Prompts
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  
                  Asks for non-leading, specific, and concrete examples of past behaviors while avoiding hypotheticals, maintaining the integrity and objectivity of their data collection.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                  <span className="feature-number">05</span>
                  Multilingual 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Communicates in over 6 languages, making your research more inclusive and accessible to a diverse range of respondents. Coming soon!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className= "feature-title">
                  <span className="feature-number">06</span>
                  Understands expressions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> 
                  Understands non-verbal cues. By interpreting facial expressions, it captures genuine emotional reactions, providing a richer context to the responses. Coming soon!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </section>


    <AnimatedElement className="global-reach-section">
      <div className="global-reach-content">
        <div className="global-reach-left">
          <h2 className="global-reach-title">Global Respondent Reach</h2>
          <p className="global-reach-description">
            Research with your users or leverage our global network of B2C and B2B participants to gain insights from diverse demographics, industries, and job titles.
          </p>
        </div>
        <div className="global-reach-right">
          <div className="world-map-background">
            <WorldMap />
          </div>
          <div className="global-reach-stats">
            <div className="stat-item">
              <div className="stat-number">3M+</div>
              <div className="stat-text">Respondents</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">15+</div>
              <div className="stat-text">Countries</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">10+</div>
              <div className="stat-text">Segmentation criteria</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">50+</div>
              <div className="stat-text">Industries</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">100+</div>
              <div className="stat-text">Job titles</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">1,000+</div>
              <div className="stat-text">Skills</div>
            </div>
          </div>
          <div className="world-map-background">
            <WorldMap />
          </div>
        </div>
      </div>
    </AnimatedElement>


        <section className='easy-use-section'>
          <div className='easy-text'>
            <h2 className='easy-text-title'> Easy to customise, create and share</h2>
            <div className='easy-text-points'>
              <p><span className="first-word">Customisation</span> options to change the voice and style to fit your brand</p>
              <p><span className="first-word">Predefined</span> feedback question templates to help you quickly create one</p>
              <p><span className="first-word">Easily</span> create a feedback survey and share with your user in your preferred channel</p>
            </div>
          </div>
          <div className='easy-visual'>
            <div className="easy-gif">
              <img src={easy_visual} className='easy-gif-content' alt="Illustration" />
            </div>
          </div>
        </section>
        <section className='insights-section'>
          <div className='insights-text'>
            <h2 className='insights-text-title'> Get Insights aggregated across interviews in real time</h2>
            <div className='insights-text-points'>
              <p><span className="first-word">Emotion</span> analysis to understand deeper insights on how the users feel when they talk about your product</p>
              <p><span className="first-word">Spend</span> no time in analysing interview transcripts</p>
              <p><span className="first-word">Get</span> top customer likes and dislikes aggregated across interviews</p>
            </div>
          </div>
          <div className='insights-visual'>
            <InsightVisual />
          </div>
        </section>
        <section className='feedback-section'>
            <h2 className='feedback-title'> Discover effortless market research with EchoAI</h2>
            <div className="button-container-hero">
                  <Button 
                    variant="contained" 
                    className='signup-button' 
                    onClick={() => handleButtonClick('https://dashboard.echovane.com/login?screen_hint=signup')}>
                    Get started for Free
                  </Button>

                  <Button variant="contained" className='book-demo-button' onClick={handleBookDemoClick}>
                    BOOK DEMO
                  </Button>
              </div>
        </section>
        <section className='footer-section'>
          <p className="copyright">
            &copy; {new Date().getFullYear()} Echovane. All rights reserved.
          </p>
          <p className="contact">contact@echovane.com</p>
        </section>
      </div>
    </Router>
  );
}

export default App;
