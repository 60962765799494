import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import worldData from './world-110m.json';

const WorldMap = () => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const { width, height } = svgRef.current.getBoundingClientRect();

    svg.attr('width', width).attr('height', height);

    const projection = d3.geoMercator()
      .fitSize([width, height], topojson.feature(worldData, worldData.objects.countries))
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    const countries = topojson.feature(worldData, worldData.objects.countries).features;

    svg.selectAll('path')
      .data(countries)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('class', 'country-path');
      
    let interval;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 0.8) {
          clearInterval(interval);
        } else {
          interval = setInterval(highlightRandomTopCountry, 2000);
        }
      });
    };
      
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.8 });
    observer.observe(svgRef.current);

    const topGdpCountries = [36, 124, 156, 250, 276, 356, 360, 376, 380, 392, 410, 484, 528, 578, 586, 608, 616, 620, 702, 724, 752, 756, 792, 826, 840, 554, 76, 643];

    const highlightRandomTopCountry = () => {
      const topCountries = countries.filter(country => topGdpCountries.includes(country.id));
      const randomTopCountry = topCountries[Math.floor(Math.random() * topCountries.length)];
      
      if (randomTopCountry) {
        svg.selectAll('path')
          .classed('highlight', d => d === randomTopCountry);
      
        svg.selectAll('.highlight-dot').remove(); // Remove existing dots
      }
    };

    // Set an interval to highlight a random top country every 2 seconds
    interval = setInterval(highlightRandomTopCountry, 2000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
      observer.disconnect();
    }
  }, []);

  return (
    <svg ref={svgRef} className="world-map-svg"></svg>
  );
};

export default WorldMap;
